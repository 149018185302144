@import '../variables';
@import '../mixins';

.lefty-badge {
  display: inline-flex;
  align-items: center;

  .text {
    font-size: var(--badge-font-size);
    line-height: var(--badge-line-height);
    font-weight: var(--badge-font-weight);
  }

  min-height: var(--badge-height);

  border: solid 1px transparent;
  padding: var(--badge-padding);
  border-radius: var(--badge-border-radius);

  &.small {
    min-height: var(--badge-small-height, 18px);
    padding: var(--badge-small-padding, 0 #{$grid-space-1});
  }

  &.square {
    border-radius: var(--badge-square-border-radius);
  }

  color: var(--badge-font-color, var(--grey-800));
  background-color: var(--grey-050);
  border: solid 1px var(--grey-080);

  &.teal,
  // DEPRECATED (use teal)
  &.primary {
    color: $teal-800;
    background-color: $teal-050;

    color: var(--badge-font-color, var(--teal-800));
    background-color: var(--teal-050);
    border-color: var(--teal-100);
  }

  &.orange,
  // DEPRECATED (use orange)
  &.warning {
    color: var(--badge-font-color, var(--orange-800));
    background-color: var(--orange-050);
    border-color: var(--orange-100);
  }

  &.yellow {
    color: var(--badge-font-color, var(--yellow-800));
    background-color: var(--yellow-050);
    border-color: var(--yellow-100);
  }

  &.red,
  // DEPRECATED (use red)
  &.danger {
    color: var(--badge-font-color, var(--red-600));
    background-color: var(--red-050);
    border-color: var(--red-100);
  }

  &.green {
    color: var(--badge-font-color, var(--green-800));
    background-color: var(--green-050);
    border-color: var(--green-100);
  }

  &.no-background {
    padding: 0;
    background-color: transparent;
    border: none;

    color: var(--grey-600);

    &.teal,
    // DEPRECATED (use teal)
    &.primary {
      color: var(--teal-500);
    }

    &.orange,
    // DEPRECATED (use orange)
    &.warning {
      color: var(--orange-800);
    }

    &.red,
    // DEPRECATED (use red)
    &.danger {
      color: var(--red-600);
    }

    &.green {
      color: var(--green-800);
    }

    &.yellow {
      color: var(--yellow-800);
    }
  }
}
