@import './fonts';
@import './grid';

@mixin lefty-radio-variables {
  --radio-size: 20px;
  --radio-border-size: 1px;
  --radio-border-radius: 10px;

  --radio-checked-border-color: var(--black);
  --radio-checked-background-color: transparent;
  --radio-unchecked-border-color: var(--black);
  --radio-unchecked-background-color: transparent;

  --radio-checked-disabled-border-color: var(--grey-180);
  --radio-checked-disabled-background-color: var(--grey-080);
  --radio-unchecked-disabled-border-color: var(--grey-180);
  --radio-unchecked-disabled-background-color: var(--grey-050);

  --radio-focus-color: var(--primary-color-300);
  --radio-focus-size: 2px;

  --radio-round-size: 10px;
  --radio-round-unckecked-background-color: transparent;
  --radio-round-checked-background-color: var(--black);
  --radio-round-unchecked-disabled-background-color: transparent;
  --radio-round-checked-disabled-background-color: var(--grey-180);
}
