@import './fonts';
@import './grid';

@mixin lefty-checkbox-variables {
  --checkbox-checked-icon-color: var(--black);
  --checkbox-checked-border-color: var(--black);

  --checkbox-unchecked-icon-color: var(--black);
  --checkbox-unchecked-border-color: var(--grey-180);

  --checkbox-size: 20px;
  --checkbox-border-radius: 4px;
  --checkbox-border-size: 1px;

  --checkbox-disabled-unchecked-border-color: var(--grey-080);
  --checkbox-disabled-unchecked-background-color: var(--grey-050);
  --checkbox-disabled-checked-background-color: var(--grey-300);
  --checkbox-disabled-checked-icon-color: var(--grey-080);
}
