@import '../variables';
@import '../mixins';

.lefty-dialog:not(material-dialog) {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;

  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  &.visible {
    display: flex;
  }

  .close-dialog {
    color: $grey-600;

    transition: color 300ms;

    &:hover {
      cursor: pointer;
      color: $grey-800;
    }
  }

  .dialog-background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background-color: $dialog-background;
  }

  .dialog-card {
    @include al-card;
    border-radius: $dialog-border-radius;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - #{$dialog-top-margin + $dialog-bottom-margin});
    overflow: hidden;
    position: relative;

    width: $dialog-small-width;
    z-index: 41;

    box-shadow: $dialog-shadow;
  }
  .dialog-header > .dialog-title {
    display: flex;
    align-items: flex-start;
    gap: $grid-space-2;
    box-sizing: border-box;

    padding-top: $dialog-header-title-padding-vertical;
    padding-bottom: $dialog-header-title-padding-vertical;
    padding-left: $dialog-header-title-padding-horizontal;
    padding-right: $dialog-header-title-padding-horizontal;

    .dialog-title-text {
      box-sizing: border-box;
      padding: $dialog-header-title-text-padding;
      flex: 1;

      h1[title],
      h2[title],
      h3[title] {
        margin: 0;
      }
    }
  }

  .dialog-header {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    background-color: var(--lefty-dialog-background-color);

    > {
      [description] {
        @include app-paragraph-default;
        color: $grey-600;
        box-sizing: border-box;
        padding-right: $dialog-header-description-padding-horizontal;
        padding-left: $dialog-header-description-padding-horizontal;
        padding-bottom: $dialog-header-description-padding-vertical;
      }
    }
  }

  [subHeader] {
    box-sizing: border-box;
    padding-right: $dialog-content-padding-horizontal;
    padding-left: $dialog-content-padding-horizontal;
  }

  .dialog-content {
    box-sizing: border-box;
    padding-left: $dialog-content-padding-horizontal;
    padding-right: $dialog-content-padding-horizontal;

    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: $grid-space-2;

    box-sizing: border-box;
    transition: all 300ms;
  }

  &.has-scroll.footer-padding-auto,
  &.footer-padding-compact {
    .dialog-footer {
      padding-top: $dialog-footer-padding-vertical-compact;
      padding-bottom: $dialog-footer-padding-vertical-compact;
      padding-left: $dialog-footer-padding-horizontal-compact;
      padding-right: $dialog-footer-padding-horizontal-compact;
    }
  }

  &:not(.has-scroll).footer-padding-auto,
  &.footer-padding-normal {
    .dialog-footer {
      padding-top: $dialog-footer-padding-vertical;
      padding-bottom: $dialog-footer-padding-vertical;
      padding-left: $dialog-footer-padding-horizontal;
      padding-right: $dialog-footer-padding-horizontal;
    }
  }

  &.footer-padding-none {
    .dialog-footer {
      padding: 0;
    }
  }

  &.x-large {
    .dialog-card {
      width: $dialog-x-large-width;
    }

    .dialog-header {
      padding-left: $x-large-dialog-header-padding-horizontal;
      padding-right: $x-large-dialog-header-padding-horizontal;
      padding-top: $x-large-dialog-header-padding-top;
    }

    .dialog-content {
      padding-left: $x-large-dialog-content-padding-horizontal;
      padding-right: $x-large-dialog-content-padding-horizontal;
    }

    [subHeader] {
      padding-left: $x-large-dialog-header-padding-horizontal;
      padding-right: $x-large-dialog-header-padding-horizontal;
    }
  }

  &.large {
    .dialog-card {
      width: $dialog-large-width;
    }
  }

  &.medium {
    .dialog-card {
      width: $dialog-medium-width;
    }
  }

  &.small {
    .dialog-card {
      width: $dialog-small-width;
    }
  }

  &.x-small {
    .dialog-card {
      width: $dialog-x-small-width;
    }
  }

  &.no-padding {
    .dialog-header,
    .dialog-content,
    .dialog-footer,
    .dialog-title,
    [description],
    [subHeader] {
      padding: 0 !important;
    }
  }

  &.no-content-padding {
    .dialog-content {
      padding: 0 !important;
    }
  }

  &.hide-header {
    &:not(.no-content-padding):not(.no-padding) {
      .dialog-content {
        padding-top: $dialog-content-padding-vertical;
      }
    }
  }

  &.hide-footer {
    &:not(.no-content-padding):not(.no-padding) {
      .dialog-content {
        // $dialog-header-padding-vertical to keep padding symetric
        padding-bottom: $dialog-content-padding-vertical;
      }
    }
  }

  &.align-left-footer {
    .dialog-footer {
      justify-content: flex-start;
    }
  }

  &.centered-footer {
    .dialog-footer {
      justify-content: center;
    }
  }

  &.has-border,
  &.has-scroll:not(.hide-header) {
    &:not(.no-content-padding):not(.no-padding) {
      .dialog-content {
        padding-top: $grid-space-2;
      }
    }
  }

  &.has-border,
  &.has-scroll:not(.hide-footer) {
    &:not(.no-content-padding):not(.no-padding) {
      .dialog-content {
        padding-bottom: $grid-space-2;
      }
    }
  }

  &.has-border:not(.hide-header):not(.hide-footer) {
    .dialog-content {
      border-top: solid 1px $grey-080;
    }
  }

  &.has-border:not(.hide-header):not(.hide-footer) {
    .dialog-content {
      border-bottom: solid 1px $grey-080;
    }
  }

  &.compact-footer {
    .dialog-footer {
      padding-top: $grid-space-3;
      padding-bottom: $grid-space-3;
    }
  }
}

@mixin _fullscreen-dialog($size) {
  .lefty-dialog:not(material-dialog):not(.no-fullscreen).#{$size} {
    .dialog-card {
      border-radius: 0;
      max-height: 100vh !important;
      height: 100%;
      width: 100%;
      box-shadow: unset;
      margin-left: 0 !important;
    }
  }

  .lefty-dialog:not(material-dialog).no-fullscreen.#{$size} {
    .dialog-card {
      width: calc(100% - $grid-space-2);
    }
  }
}

@media (max-width: $dialog-x-large-width) {
  @include _fullscreen-dialog('x-large');
}

@media (max-width: $dialog-large-width) {
  @include _fullscreen-dialog('large');
}

@media (max-width: $dialog-medium-width) {
  @include _fullscreen-dialog('medium');
}

@media (max-width: $dialog-small-width) {
  @include _fullscreen-dialog('small');
}

@media (max-width: $dialog-x-small-width) {
  @include _fullscreen-dialog('x-small');
}
