@import './card';
@import './grid';
@import './shadow';
@import './colors';

// LEGACY
$dropdown-shadow: $base-shadow;
$dropdown-radius: 4px;
$dropdown-radius-large: 8px;
$dropdown-background: #fff;
$dropdown-padding: 0;
$dropdown-font-size: var(--body-1-font-size);
$dropdown-font-color: $black;
//////////

$popup-shadow: $base-shadow;
$popup-radius: 8px;
$popup-radius-large: 12px;
$popup-background: #fff;
$popup-padding: 0;
$popup-font-size: var(--body-1-font-size);
$popup-font-color: $black;
$popup-container-offset: $grid-space-1;
$popup-max-height: 480px;

$popup-show-transition: transform 150ms cubic-bezier(0.4, 0, 1, 1);
$popup-hide-transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);

$popup-show-transform: scale3d(0, 0, 1);
$popup-hide-transform: scale3d(1, 1, 1);

$dialog-content-h-padding: 30px;
$dialog-background: #fff;
$dialog-content-h-large-padding: 50px;
$dialog-content-v-padding: 30px;
$dialog-border-radius: $al-card-border-radius;
$dialog-footer-h-padding: $dialog-content-h-padding;
$dialog-footer-v-padding: $grid-space-3;

// TODO: migrate other popup variable to CSS variable
@mixin lefty-popup-variables {
  --popup-z-index: 4;
}
