@import './fonts';
@import './grid';

@mixin lefty-badge-variables {
  --badge-font-size: var(--caption-1-font-size);
  --badge-line-height: 100%;
  --badge-font-weight: var(--caption-1-font-weight);
  --badge-height: 20px;

  --badge-padding: 0 #{$grid-space-2};
  --badge-border-radius: 10px;
  --badge-square-border-radius: 4px;
}
