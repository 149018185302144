@import './colors';
@import './grid';
@import './fonts';

$select-font-color: $black;
$select-placeholder-font-color: $black;
$select-placeholder-empty-font-color: $grey-600;

$select-icon-color: $grey-400;
$select-count-color: $primary-color;
$select-remove-icon-color: $primary-color;
$select-remove-icon-size: 14px;

$select-border-size: 1px;
$select-border-active-color: $primary-color;

$select-disabled-background-color: $grey-020;
$select-disabled-border-color: $grey-080;
$select-disabled-font-color: $grey-300;

$select-invalid-border-color: $red-400;

$select-button-padding: var(
  --select-button-padding,
  0 $grid-space-1 1px $grid-space-2
);
