@import './colors';
@import './grid';
@import './fonts';

$list-padding: $grid-space-1 0;

$list-item-height: 38px;
$list-item-font-color: var(--black);
$list-item-font-size: var(--body-1-font-size);
$list-item-font-weight: var(--body-1-font-weight);
$list-item-line-height: var(--body-1-line-height);
$list-item-padding: 0 $grid-space-3;

$list-item-hover-bckg-color: $grey-020;
$list-item-selected-bckg-color: $primary-color-050;

$list-item-font-color: $black;
$list-item-selected-font-color: $primary-color;
$list-item-disabled-font-color: $grey-300;

$list-item-checkbox-margin-right: $grid-space-2;
