@import '../variables';
@import './fonts';

@mixin lefty-chip {
  display: inline-flex;
  align-items: center;

  position: relative;
  box-sizing: border-box;

  line-height: $chip-height;
  min-height: $chip-height;
  padding: 0 $chip-horizontal-padding;
  border-radius: $chip-border-radius;

  // light is default design
  background-color: white;

  &.dark {
    background-color: var(--grey-050);
  }

  .icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: $chip-content-gap;

    .icon {
      color: var(--grey-600);
    }
  }

  .action {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $chip-height;
    width: $chip-height;

    position: absolute;
    right: 0;

    border-radius: 4px;

    lefty-icon {
      color: var(--primary-color);
    }

    &:hover {
      cursor: pointer;

      lefty-icon {
        color: var(--primary-color-800);
      }
    }

    &:focus-visible {
      @include focus-style;
    }

    &.custom-action:empty {
      display: none;
    }
  }

  &.highlight {
    background-color: var(--teal-050);

    .icon {
      color: var(--teal-500);
    }
  }

  .value {
    @include app-body-1;
    display: inline-block;
  }

  .name {
    @include app-body-1;
    display: inline-block;
    color: $grey-600;
  }

  &.no-overflow {
    .value {
      overflow: hidden;
      max-width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.has-action {
    padding-right: $chip-height + $chip-content-gap;
  }

  &.has-icon {
    padding-left: $chip-content-gap + $chip-icon-width + $chip-content-gap;
  }

  &.ghost {
    @include ghost-animation;
    width: 60px;
  }
}
