@import '../variables';
@import '../mixins';

.al-card {
  @include al-card();

  &.with-border {
    border: $al-card-border;

    &.clickable {
      &:hover,
      &.active {
        box-shadow: $al-card-border-shadow-hover;
      }
    }
  }

  &.selectable {
    border-radius: $al-card-selectable-border-radius;

    &.with-padding {
      padding: $grid-space-3;
    }

    &:not(.active) {
      border-color: var(--grey-080);
    }

    &:hover:not(.no-hover):not(.active) {
      border-color: var(--grey-180);
      cursor: pointer;
    }

    &.active {
      border-color: var(--grey-600);
      background-color: var(--grey-050);
    }
  }

  &.with-padding {
    padding: $al-card-padding;

    &.compact {
      padding: $al-card-compact-padding;
    }
  }

  &.with-shadow {
    box-shadow: $al-card-box-shadow;

    &.on-hover,
    &.clickable {
      &:hover,
      &.active {
        box-shadow: $al-card-box-shadow-hover;
      }
    }
  }

  .card-actions {
    display: flex;
    justify-content: center;
    margin-top: $grid-item-gap * 2;
    width: 100%;
  }

  .card-content {
    margin-top: $grid-item-gap;
  }

  .card-subtitle {
    // apply style only if a typography style is not apply already
    &:not(h4):not(h3):not(h2):not(h1):not(.body-1):not(.caption-1) {
      @include app-body-1();
    }

    margin-top: 5px;
    color: $grey-600 !important;

    &.center {
      text-align: center;
    }
  }
}
