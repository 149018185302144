@import 'packages/lefty-assets/src/lib/css/variables';
@import 'packages/lefty-assets/src/lib/css/mixins';

@import 'air-datepicker/air-datepicker.css';

.air-datepicker {
  --adp-font-family: var(--font);
  --adp-font-size: var(--body-1-font-size);
  --adp-width: 263px;
  --adp-padding: #{$grid-space-1};
  --adp-background-color-hover: #{$grey-080};

  --adp-color: #{$grey-800};
  --adp-color-secondary: #{$grey-600};
  --adp-accent-color: #{$primary-color};

  --adp-cell-background-color-selected: #{$primary-color};
  --adp-cell-background-color-selected-hover: #{$primary-color-300};
  --adp-cell-background-color-hover: var(--adp-background-color-hover);
  --adp-cell-background-color-in-range: #{$primary-color-050};

  --adp-background-color-active: #{$grey-080};
  --adp-background-color-in-range: #{$primary-color-100};
  --adp-background-color-in-range-focused: #{$primary-color-100};
  --adp-background-color-in-range: #{$primary-color-050};
  --adp-background-color-selected-other-month-focused: #{$primary-color};
  --adp-background-color-selected-other-month: #{$primary-color};

  --adp-border-color: transparent;
  --adp-border-color-inner: transparent;
  --adp-border-radius: var(--button-border-radius);
  --adp-border-color-inline: transparent;
  --adp-nav-height: var(--button-height);
  --adp-nav-action-size: var(--button-height);

  --adp-nav-arrow-color: var(--adp-color-secondary);
  --adp-nav-color-secondary: var(--adp-color-secondary);

  --adp-day-name-color: #{$grey-600};
  --adp-day-name-color-hover: #{$grey-600};

  --adp-day-cell-height: 36px;
  --adp-day-cell-width: 36px;
  --adp-cell-border-radius: 50%;
}

.air-datepicker-cell {
  &.-month-,
  &.-year- {
    border-radius: 4px;
  }

  &.-disabled- {
    cursor: not-allowed;
  }
}
