@use 'sass:math';

@import '../variables';
@import '../mixins';

button:not(.selector-btn),
.button:not(.selector-btn) {
  @include button-base;
  @include button-medium-size;
  @include button-secondary;

  &.small {
    @include button-small-size;
  }

  &.medium {
    @include button-medium-size;
  }

  &.large {
    @include button-large-size;
  }

  &.primary {
    @include button-primary;
  }

  &.secondary {
    @include button-secondary;
  }

  &.caution {
    @include button-caution;
  }

  &.destructive {
    @include button-destructive;
  }

  &.outline {
    @include button-outline;

    &.checked {
      @include button-outline-checked;
    }
  }

  &.plain {
    @include button-plain;
  }
}

.btn-group {
  display: inline-flex;

  &:not(.outline) {
    position: relative;
    // btn group has a single size
    height: var(--button-group-height);
    box-sizing: border-box;
    gap: var(--button-group-gap);

    &.round-radius {
      padding: $grid-space-1;
      border-radius: 20px;
      gap: $grid-space-1;

      button,
      .button {
        border-radius: 15px;
      }
    }

    &:not(.round-radius) {
      --group-padding: 3px;
      padding: var(--group-padding);
      border-radius: var(--button-border-radius);

      button,
      .button {
        border-radius: calc(var(--button-border-radius) - var(--group-padding));
      }
    }

    &:not(.white) {
      background-color: var(--button-group-background-color);
    }

    &.white {
      background-color: #fff;
    }

    .button,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px transparent;
      background-color: transparent;
      font-weight: var(--button-group-font-weight);
      height: 100%;
      font-family: inherit;
      border-radius: var(--button-group-border-radius);
      gap: math.div($grid-space-1, 2);

      &:not(:disabled) {
        &.active {
          color: var(--button-group-active-color);
          background-color: var(--button-group-active-background-color);
          box-shadow: var(--button-group-active-shadow);
          font-weight: var(--button-group-active-font-weight);
        }

        &:not(.active) {
          background-color: var(--button-group-unactive-background-color);
          color: var(--button-group-unactive-color);

          &:hover {
            border-color: var(--button-group-background-color);
            background-color: var(--button-group-hover-background-color);
            color: var(--button-group-active-color);
          }
        }
      }

      &:disabled {
        color: var(--button-group-disabled-color);
        background-color: var(--button-group-disabled-background-color);

        &::before {
          display: none !important;
        }
      }
    }
  }

  &.outline {
    border-radius: var(--button-group-border-radius);

    .button,
    button {
      border: solid var(--button-border-size)
        var(--button-group-outline-border-color);
      // btn group has a single size
      height: var(--button-group-height);
      color: var(--button-group-outline-font-color);
      background-color: var(--button-group-outline-background-color);
      font-weight: var(--button-group-font-weight);
      transition: all 300ms;

      position: relative;
      z-index: 0;

      &:not(:first-child) {
        margin-left: -1px;
      }

      &.active {
        color: var(--button-group-outline-active-font-color);
        background-color: var(--button-group-outline-active-background-color);
        border-color: var(--button-group-outline-active-border-color);
        font-weight: var(--button-group-outline-active-font-weight);
        z-index: 2;
      }

      &:hover:not(.active) {
        color: var(--button-group-outline-hover-font-color);
        background-color: var(--button-group-outline-hover-background-color);
        border-color: var(--button-group-outline-hover-border-color);
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
