@mixin lefty-toggle-variables {
  --toggle-container-checked-background-color: var(--black);
  --toggle-container-checked-border-color: var(--black);

  --toggle-container-unchecked-background-color: white;
  --toggle-container-unchecked-border-color: var(--grey-300);

  --toggle-checked-color: white;
  --toggle-unchecked-color: var(--grey-080);

  --toggle-container-disabled-border-color: var(--grey-180);
  --toggle-container-disabled-background-color: var(--grey-050);

  --toggle-disabled-backround-color: white;

  --toggle-container-border-radius: 14px;
  --toggle-container-border-width: 1px;

  --toggle-container-large-width: 56px;
  --toggle-container-large-height: 28px;
  --toggle-large-icon-size: 24px;
  --toggle-large-size: 24px;

  --toggle-container-medium-width: 44px;
  --toggle-container-medium-height: 22px;
  --toggle-medium-icon-size: 20px;
  --toggle-medium-size: 18px;

  --toggle-container-small-width: 32px;
  --toggle-container-small-height: 16px;
  --toggle-small-icon-size: 16px;
  --toggle-small-size: 12px;
}
