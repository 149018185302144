@import '../variables';
@import './fonts';

@mixin app-heading-1 {
  font-size: var(--heading-1-font-size);
  color: var(--heading-font-color);
  font-weight: var(--heading-1-font-weight);
  line-height: var(--heading-1-line-height);

  &.fancy {
    font-family: var(--fancy-font);

    font-size: rem(36px);
    line-height: 110%;
  }

  &.xl {
    font-size: var(--heading-xl-font-size);
    font-weight: var(--heading-xl-font-weight);
  }

  &.xxl {
    font-size: var(--heading-xxl-font-size);
    font-weight: var(--heading-xxl-font-weight);
  }
}

@mixin app-heading-2 {
  font-size: var(--heading-2-font-size);
  color: var(--heading-font-color);
  font-weight: var(--heading-2-font-weight);
  line-height: var(--heading-2-line-height);

  &.fancy {
    font-family: var(--fancy-font);
  }
}

@mixin app-heading-3 {
  font-size: var(--heading-3-font-size);
  color: var(--heading-font-color);
  font-weight: var(--heading-3-font-weight);
  line-height: var(--heading-3-line-height);

  &.fancy {
    font-family: var(--fancy-font);
  }
}

@mixin app-heading-4 {
  font-size: var(--heading-4-font-size);
  color: var(--heading-font-color);
  font-weight: var(--heading-4-font-weight);
  line-height: var(--heading-4-line-height);

  &.fancy {
    font-family: var(--fancy-font);
  }
}

@mixin app-heading-5 {
  font-size: var(--heading-5-font-size);
  color: var(--heading-font-color);
  font-weight: var(--heading-5-font-weight);
  line-height: var(--heading-5-line-height);

  &.fancy {
    font-family: var(--fancy-font);
  }
}

@mixin app-heading-6 {
  font-size: var(--heading-6-font-size);
  color: var(--heading-font-color);
  font-weight: var(--heading-6-font-weight);
  line-height: var(--heading-6-line-height);

  &.fancy {
    font-family: var(--fancy-font);
  }
}

@mixin app-body-1 {
  font-size: var(--body-1-font-size);
  font-weight: var(--body-1-font-weight);
  line-height: var(--body-1-line-height);
  color: var(--body-1-font-color);
}

@mixin app-caption-1 {
  font-size: var(--caption-1-font-size);
  font-weight: var(--caption-1-font-weight);
  line-height: var(--caption-1-line-height);
  color: var(--caption-1-font-color);
}

@mixin app-paragraph-x-large {
  font-size: var(--paragraph-x-large-font-size);
  line-height: var(--paragraph-x-large-line-height);
  font-weight: var(--paragraph-x-large-font-weight);
  color: var(--paragraph-font-color);

  a {
    font-size: inherit !important;
  }
}

@mixin app-paragraph-large {
  font-size: var(--paragraph-large-font-size);
  line-height: var(--paragraph-large-line-height);
  font-weight: var(--paragraph-large-font-weight);
  color: var(--paragraph-font-color);

  a {
    font-size: inherit !important;
  }
}

@mixin app-paragraph-medium {
  font-size: var(--paragraph-medium-font-size);
  line-height: var(--paragraph-medium-line-height);
  font-weight: var(--paragraph-medium-font-weight);
  color: var(--paragraph-font-color);

  a {
    font-size: inherit !important;
  }
}

@mixin app-paragraph-default {
  @include app-paragraph-medium;
}

@mixin app-paragraph-small {
  font-size: var(--paragraph-small-font-size);
  line-height: var(--paragraph-small-line-height);
  font-weight: var(--paragraph-small-font-weight);
  color: var(--paragraph-font-color);

  a {
    font-size: inherit !important;
  }
}

@mixin app-link(
  $color: $link-primary-color,
  $secondary-color: $link-primary-color-darker
) {
  text-decoration: none;
  transition: color 0.2s;
  @include app-body-1;
  color: $color;

  &:visited {
    color: $color;
  }

  &:hover {
    cursor: pointer;
    color: $secondary-color;
  }

  &:not([leftyButton]).underline {
    text-decoration: underline;
  }

  &.unactive {
    color: $grey-400;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: $grey-400;
  }
}

@mixin error-message {
  @include app-body-1;
  line-height: 1;
  color: $red-600;
}

@mixin form-label {
  display: flex;
  margin-bottom: $grid-space-1;

  &:not(.large) {
    @include app-body-1;
  }

  &.large {
    @include app-heading-4;
  }

  .required {
    margin-left: 3px;
    color: $danger-color;
  }

  .optional {
    color: $grey-400;
    margin-left: 3px;
  }
}
