@import '../variables';
@import './buttons';
@import './fonts';

@mixin select-button-base($icon-selector) {
  @include button-base;
  @include button-subtle;
  @include button-large-size;
  @include select-icon-style($icon-selector);

  justify-content: space-between;
  padding: $select-button-padding;
}

@mixin select-icon-style($selector) {
  #{$selector} {
    color: $select-icon-color !important;
    margin-left: $grid-space-2;
    transition: 300ms transform;
    font-size: 20px;

    &.removeIcon {
      color: $select-remove-icon-color !important;
      font-size: $select-remove-icon-size !important;
    }
  }

  &.active {
    #{$selector} {
      transform: rotate(180deg);
    }
  }
}

@mixin select-button-small {
  @include button-small-size;
  padding: $select-button-padding;
}

@mixin select-button-large {
  @include button-large-size;
  padding: $select-button-padding;
}

@mixin select-button {
  @include select-button-base($icon-selector: 'lefty-icon');

  .button-content {
    display: inline-flex;
    align-items: center;
    overflow: hidden;

    .placeholder,
    .formatted-value {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    .placeholder {
      color: $select-placeholder-font-color;

      &.empty {
        color: $select-placeholder-empty-font-color;
      }

      .count {
        color: $select-count-color;
      }
    }
  }

  &.small {
    @include select-button-small;
  }

  // selector does not have medium size
  // we use large size instead
  &.medium,
  &.large {
    @include select-button-large;
  }

  &.outline {
    @include button-outline;
  }

  &:not(.outline) {
    // select button must have border (we need to change border color on focus)
    // Outline already has border
    border: solid $select-border-size transparent;
  }

  &.plain {
    @include button-plain;

    lefty-icon {
      margin-left: $grid-space-1;
    }
  }

  &.ghost {
    @include button-ghost;
  }

  &.subtle {
    @include button-subtle;
  }

  &.disabled,
  &:disabled {
    border-color: $grey-080;

    .placeholder {
      color: $grey-300 !important;
    }
  }

  &:focus-visible {
    @include focus-style;
  }
}

@mixin select-button-wrapper {
  &.ng-invalid {
    &.ng-dirty,
    &.ng-touched {
      ::ng-deep .selector-btn {
        border-color: $select-invalid-border-color !important;
      }
    }
  }

  &.empty {
    .selector-btn {
      &:not(:disabled) {
        color: $select-placeholder-empty-font-color;
      }
    }
  }

  &:not(.empty) {
    .selector-btn {
      color: $select-placeholder-font-color;
    }
  }

  &:focus-visible {
    .selector-btn {
      @include focus-style;
    }
  }
}

@mixin override-material-dropdown-button($selector) {
  &.ng-invalid {
    &.ng-dirty,
    &.ng-touched {
      ::ng-deep #{$selector} {
        .button {
          @include input-invalid-style;
        }
      }
    }
  }

  ::ng-deep #{$selector} {
    .button {
      @include select-button-base($icon-selector: 'glyph');
    }

    &.active {
      .button {
        glyph {
          transform: rotate(180deg);
        }
      }
    }

    &.small {
      .button {
        @include select-button-small;
      }
    }

    // selector does not have medium size
    // we use large size instead
    &.medium,
    &.large {
      .button {
        @include select-button-large;
      }
    }

    &.outline {
      .button {
        @include button-outline;
      }
    }

    &:not(.outline) {
      .button {
        // select button must have border (we need to change border color on focus)
        // Outline already has border
        border: solid $select-border-size transparent;
      }
    }

    &.subtle {
      .button {
        @include button-subtle;
      }
    }

    &.disabled,
    &:disabled {
      .button {
        border-color: $grey-080;

        .placeholder {
          color: $grey-300 !important;
        }
      }
    }

    &:focus-visible {
      .button {
        @include focus-style;
      }
    }
  }
}
